<template>
  <div>
    <div class="flix-flex flix-flex-list" v-if="!$store.state.save.success">
      <div class="item preview flix-form-group">
        <h3>{{ $t("register.preview") }}</h3>
        <flix-calendar id="2" :data="data" callback="calendarSettings" />
      </div>
      <div
        class="item register flix-mt-50"
        v-if="!$store.state.save.registredUser"
      >
        <form>
          <account />
        </form>
      </div>
    </div>
    <div
      class="item flix-flex flix-flex-center flix-mt-50"
      v-if="$store.state.save.error"
    >
      <div
        class="flix-text-danger flix-html-strong"
        v-html="$store.state.save.error"
      ></div>
    </div>
    <div
      v-else-if="!$store.state.save.success && $store.state.save.registredUser"
      class="item flix-flex flix-flex-center flix-mt-50"
    >
      <success />
    </div>
    <div
      class="item flix-flex flix-flex-center flix-mt-50"
      v-else-if="$store.state.save.success"
    >
      <success />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    account() {
      return import("./account");
    },
    success() {
      return import("./success");
    }
  },
  props: {},
  data() {
    return {
      error: false,
      data: btoa(
        JSON.stringify({
          data: this.getData(),
          services: this.$store.state.service.services
        })
      )
    };
  },
  computed: {},
  mounted() {
    this.autoSave();
  },
  methods: {
    autoSave() {
      if (!this.$store.state.save.registredUser) {
        return false;
      }
      this.$store.dispatch(
        "save/updateSave",
        function(state, result) {
          this.$store.dispatch("save/setUpdate", [state, result]);
        }.bind(this)
      );
    },
    getData() {
      var d = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      if (d.type === "services") {
        d.type = "datepicker";
      }
      d.lang = this.$i18n.locale;
      return d;
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
.container
  display: flex
  margin-left: -10px
  margin-right: -10px
  h3
    margin-top: 0
    text-align: center
  .item
    flex: 1
  .item.register
    background-color: $light-grey
    border-radius: 3px
    padding: 30px
    box-sizing: border-box
  .item.preview
    flex: 1
    line-height: 2em

@media(max-width: 500px)
  .container
    flex-direction: column-reverse
    margin: 0
    .item.register, .item.preview
      width: 100%
      flex: 1
      padding: 5px
</style>
